@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

body {
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Tajawal", sans-serif;
scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  direction: rtl;
  overflow-x: hidden;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.line-clamp {
  display: -webkit-box;
  /* For Safari */
  -webkit-box-orient: vertical;
  /* Vertical orientation */
  -webkit-line-clamp: 2;
  /* Limit to 2 lines */
  overflow: hidden;
  /* Hide overflow */
  text-overflow: ellipsis;
  /* Show ellipsis */
}


/* For WebKit browsers */
::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the track */
}

::-webkit-scrollbar-thumb {
  background: #0F2028;
  /* Color of the thumb */
  border-radius: 20px;
  /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the thumb on hover */
}

.bg-footer {
  background: rgb(15, 32, 40);
  background: linear-gradient(145deg, rgba(15, 32, 40, 1) 19%, rgba(20, 37, 42, 1) 32%, rgba(27, 44, 44, 1) 47%, rgba(29, 46, 45, 1) 56%, rgba(35, 52, 47, 1) 63%, rgba(56, 73, 54, 1) 74%);


}

.scroll {
  position: relative;
  display: flex;
  width: 100vw;
  overflow: hidden;
}

.scroll-content {
  display: flex;
  width: calc(200%);
  /* Ensures smooth scrolling */
  animation: scroll var(--t, 10s) linear infinite;
  /* Adds a default animation duration if --t is not set */
}

.scroll-content>div {
  display: flex;
  flex-shrink: 0;
}

.scroll-content div span {
  display: inline-flex;
  height: 80px;
  margin-right: 4rem;
}

.scroll-content div span img {
  height: 60px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}